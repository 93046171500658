@import "@/styles/_variables.scss";




























































.uk-card {
  min-height: 185px;
  min-width: 185px;
}
span.uk-text-small {
  color: var(--white-color);
}
